/**
*
* Testing
*
**/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}
@media (max-width: 360px) {
  html {
    font-size: 45%;
    overflow-x: hidden;
  }
}
@media (min-width: 361px) and (max-width: 425px) {
  html {
    font-size: 45%;
    overflow-x: hidden;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  html {
    font-size: 45%;
    overflow-x: hidden;
  }
}
@media (min-width: 769px) and (max-width: 1365px) {
  html {
    font-size: 62.5%;
    overflow-x: hidden;
  }
}
@media (min-width: 1366px) {
  html {
    font-size: 62.5%;
    overflow-x: hidden;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 65.5%;
    overflow-x: hidden;
  }
}
@keyframes blink {
  0% { background-color: blue;  color: white;}
  50% { background-color: transparent; color: black;}
  100% { background-color: blue; color: white;}
}

.blinking {
  animation: blink 2s infinite;
  border: none;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
  overflow-x: hidden;
}

.main-wrapper {
  height: 100vh;
  display: flex;
}

.left-nav {
  background-color: #0E1827;
  width: 20rem;
  flex-shrink: 0;
  overflow: auto;
}
.left-nav__logo {
  height: 14.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2.4rem 2.4rem;
  border-bottom: 0.1rem solid #27303D;
}
.left-nav__logo--img {
  width: 12rem;
}
.left-nav__nav {
  padding-left: 0.8rem;
  padding-right: 1.3rem;
}
.left-nav__nav--btn {
  width: 100%;
  height: 4.2rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0 0.7rem;
  margin: 0.3rem 0;
}
.left-nav__nav--btn.active {
  background: rgba(217, 217, 217, 0.3);
}
.left-nav__nav--btn .nav-btn-text {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #B7BABF;
  text-align: left;
  flex-grow: 1;
}
.left-nav__nav--btn .nav-btn-icon {
  width: 2.9rem;
  margin-right: 1rem;
}
.left-nav__nav--btn .arrow-icon {
  width: 0.7rem;
  transition: 0.5s;
  transform: rotate(0deg);
}
.left-nav__nav--sub-nav {
  width: 100%;
}
.left-nav__nav--sub-nav .sub-nav-btn {
  width: 100%;
  height: 4.2rem;
  background: none;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #B7BABF;
  text-align: left;
  text-decoration: none;
  padding-left: 4.7rem;
}
.left-nav__nav--sub-nav .sub-nav-btn.active {
  background: rgba(217, 217, 217, 0.3);
}

.content-box {
  background-color: #ffffff;
  flex-grow: 1;
  border-radius: 2rem;
  padding: 1.4rem;
  display: flex;
  height: 1rem;
}
.content-box.transparent {
  background: none;
  border-radius: 0;
  padding: 0;
}

.data-table {
  flex-grow: 1;
  overflow: auto;
  padding: 0 1.2rem;
}

.data-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures uniform column widths */
}

.data-table table thead {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1E1E1E;
}

.data-table table thead th {
  background: #ffffff;
  height: 6rem;
  border-bottom: 0.2rem solid #D8DCE2;
  position: sticky;
  top: 0;
  text-align: center; /* Center align text in header */
  padding: 0.8rem; /* Padding for consistent spacing */
}

.data-table table tbody {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #1E1E1E;
}

.data-table table tbody td {
  height: 4.6rem;
  border-bottom: 0.2rem solid #D8DCE2;
  text-align: center; /* Center align text in cells */
  padding: 0.8rem; /* Padding for consistent spacing */
}

.data-table table .view-btn {
  
  color: #ffffff;
  background: #0F54A2;
  border: none;
  height: 2.4rem;
  border-radius: 2.4rem;
  border: 0.1rem solid #0F54A2;
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Center align buttons */
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  
  padding: 0 0.8rem;
  cursor: pointer;
  transition: 0.5s;
}

.data-table table .view-btn:hover {
  color: #0F54A2;
  background-color: #ffffff;
}

.data-table table .action-buttons {
  display: flex;
  justify-content: center; /* Align buttons to the center */
  align-items: center; /* Vertically align buttons */
  gap: 0.5rem; /* Add spacing between buttons */
}

.data-table table .action-buttons .accept-btn {
  border-radius: 5px;
}

.data-table table .action-buttons .reject-btn {
  border-radius: 5px;
}

.data-table-title {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #000000;
  line-height: normal;
  margin-bottom: 1rem;
}

.data-table-2 {
  flex-grow: 1;
  overflow: auto;
}
.data-table-2 table {
  width: 100%;
  border-collapse: collapse;
}
.data-table-2 table thead {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1E1E1E;
}
.data-table-2 table thead th {
  background: #ffffff;
  height: 3.4rem;
  border-top: 0.1rem solid #CACACA;
  border-bottom: 0.1rem solid #CACACA;
}
.data-table-2 table thead th:first-child {
  border-left: 0.1rem solid #CACACA;
}
.data-table-2 table thead th:last-child {
  border-right: 0.1rem solid #CACACA;
}
.data-table-2 table tbody {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #1E1E1E;
}
.data-table-2 table tbody td {
  height: 3.8rem;
  border-bottom: 0.2rem solid #D8DCE2;
  text-align: center;
}
.data-table-2 table .view-btn {
  background: #ffffff;
  border: none;
  height: 2.4rem;
  border-radius: 2.4rem;
  border: 0.1rem solid #0F54A2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #0F54A2;
  padding: 0 1.6rem;
  margin: auto;
  cursor: pointer;
  transition: 0.5s;
}
.data-table-2 table .view-btn:hover {
  color: #ffffff;
  background-color: #0F54A2;
}

.scroll-sm::-webkit-scrollbar {
  width: 0.8rem;
}

.scroll-sm::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background: #ffffff;
}
.scroll-sm::-webkit-scrollbar-track.scroll-bg-light {
  background: #eaeced;
}

.scroll-sm::-webkit-scrollbar-thumb {
  border-radius: 0.8rem;
  background: #B7BABF;
  border: none;
}

.scroll-sm::-webkit-scrollbar-thumb:hover {
  background: #0F54A2;
}

.scroll-sm::-webkit-scrollbar-thumb:active {
  background: #0F54A2;
}

.scroll-sm2::-webkit-scrollbar {
  width: 0.8rem;
}

.scroll-sm2::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background: #eaeced;
}

.scroll-sm2::-webkit-scrollbar-thumb {
  border-radius: 0.8rem;
  background: #B7BABF;
  border: none;
}

.scroll-sm2::-webkit-scrollbar-thumb:hover {
  background: #0F54A2;
}

.scroll-sm2::-webkit-scrollbar-thumb:active {
  background: #0F54A2;
}

.modal-dark-bg {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.modal-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

/* Primary Button */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Success Button */
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Danger Button */
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

/* Disabled Button */
.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.modal {
  width: 104.4rem;
  height: 67.5rem;
  background: #ffffff;
  z-index: 110;
  border-radius: 2rem;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  padding: 4rem 2.4rem 2.4rem 4rem;
  display: flex;
  flex-direction: column;
}
.modal__close-btn {
  background-color: #B23F3F;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: 2.5rem;
  position: absolute;
  top: 4.8rem;
  right: 3.2rem;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.modal__close-btn:hover {
  background-color: #792b2b;
}
.modal__close-btn .icon {
  width: 1rem;
}
.modal__check-btn {
  background-color: #0F54A2;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: 2.5rem;
  position: absolute;
  top: 5.4rem;
  right: 3.2rem;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.modal__check-btn:hover {
  background-color: #09305c;
}
.modal__check-btn .icon {
  width: 1.4rem;
}
.modal__title {
  font-family: "Inter", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 4rem;
  color: #000000;
  margin-bottom: 3.2rem;
  flex-shrink: 0;
}
.modal__sub-title {
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  color: #0F54A2;
  border-bottom: 0.1rem solid #0F54A2;
  position: absolute;
  top: 1.6rem;
  left: 50%;
  transform: translateX(-50%);
}
.modal__content {
  flex-grow: 1;
  overflow: auto;
  padding-right: 1.6rem;
}
.modal__footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.6rem;
}
.modal__footer .form-action-btn {
  width: 15rem;
}
.modal.light-center {
  background: #EFEFEF;
}
.modal.light-center .modal__title {
  text-align: center;
}
.modal.light-center .modal__footer {
  justify-content: center;
}
.modal.alert {
  background: #EFEFEF;
  padding: 2.4rem 4rem;
}
.modal.alert .modal__close-btn {
  display: none;
}
.modal.alert .modal__content {
  padding-right: 0;
}

.grid-4-form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.8rem;
  row-gap: 2.2rem;
}
.grid-4-form + .grid-4-form {
  margin-top: 2.8rem;
}

.grid-3-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2.8rem;
  row-gap: 2.2rem;
}
.grid-3-form + .grid-3-form {
  margin-top: 2.8rem;
}

.grid-2-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.8rem;
  row-gap: 2.2rem;
}
.grid-2-form + .grid-2-form {
  margin-top: 2.8rem;
}
.grid-2-form + .grid-4-form {
  margin-top: 2.8rem;
}

.grid-1-form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2.8rem;
  row-gap: 2.2rem;
}
.grid-1-form + .grid-1-form {
  margin-top: 2.8rem;
}

.form-control {
  display: flex;
  flex-direction: column;
}
.form-control__label {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 0.8rem;
}
.form-control__error {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 0.8;
  color: #ff0000;
  margin-bottom: 0.8rem;
}
.form-control__input {
  width: 100%;
  height: 4rem;
  background-color: #ffffff;
  border: 0.1rem solid #CACACA;
  border-radius: 0.4rem;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #1E1E1E;
  font-size: 1.3rem;
  font-weight: 400;
}

.form-control__input::placeholder {
  color: #ABABAB;
  font-weight: 300;
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 2.5em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 3em;
  line-height: 1.9em;
  margin: 0.166em;
  height: 3em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: .5em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1.5em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1.5em;
}
.form-control__input:disabled {
  background-color: #D3D3D3;
}
.form-control__textarea {
  width: 100%;
  background-color: #ffffff;
  border: 0.1rem solid #CACACA;
  border-radius: 0.4rem;
  padding: 1.2rem;
  font-family: "Inter", sans-serif;
  color: #1E1E1E;
  font-size: 1.3rem;
  font-weight: 400;
  resize: none;
}
.form-control__textarea::placeholder {
  color: #ABABAB;
  font-weight: 300;
}

.form-add-new-btn {
  height: 4rem;
  background-color: #0F54A2;
  border: none;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
}
.form-add-new-btn:hover {
  background-color: #0b3c73;
}
.form-add-new-btn__icon {
  margin-right: 1.4rem;
}
.form-add-new-btn:disabled {
  color: #898989;
  background-color: #D3D3D3;
  border: 0.1rem solid #CACACA;
}

.form-outline-btn {
  background-color: #ffffff;
  border: 0.1rem solid #0F54A2;
  width: 13.5rem;
  height: 4rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #0F54A2;
  cursor: pointer;
  transition: 0.5s;
}
.form-outline-btn:hover {
  color: #ffffff;
  background-color: #0F54A2;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mt-25 {
  margin-top: 2.5rem;
}

.mt-28 {
  margin-top: 2.8rem;
}

.ml-32 {
  margin-left: 3.2rem;
}

.border-top-none {
  border-top: none !important;
}

.form-action-btn {
  background-color: #0F54A2;
  border: none;
  width: 18rem;
  height: 4rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
}
.form-action-btn:hover {
  background-color: #0b3c73;
}
.form-action-btn.danger {
  background-color: #A20F0F;
}
.form-action-btn.danger:hover {
  background-color: #730b0b;
}
.form-action-btn + .form-action-btn {
  margin-left: 0.6rem;
}

.score-row {
  display: flex;
  align-items: center;
}
.score-row__label {
  background-color: #CCD2E3;
  height: 3.2rem;
  border-radius: 0.4rem;
  flex-grow: 1;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #000000;
  line-height: 1;
  display: flex;
  align-items: center;
  padding-left: 0.6rem;
}
.score-row__input {
  width: 4.4rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  background-color: #ffffff;
  border: 0.1rem solid #CACACA;
  margin-right: 0.4rem;
  margin-left: 0.6rem;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
  padding: 0 0.8rem;
}
.score-row__text {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #000000;
}
.score-row + .score-row {
  margin-top: 1.6rem;
}

.job-box {
  background-color: #ffffff;
  width: 100%;
  height: 12.2rem;
  border-radius: 0.7rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.25);
  padding-right: 4rem;
  padding-left: 2.4rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.job-box__detail {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 3.4rem;
}
.job-box__detail--title {
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #000000;
  line-height: normal;
}
.job-box__detail--text {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #898989;
  line-height: 1.3rem;
}
.job-box__detail--btn-row {
  display: flex;
}
.job-box__detail--btn-row .row-btn {
  width: 7rem;
  height: 3.2rem;
  border-radius: 1rem;
  border: 0.1rem solid #0F54A2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #0F54A2;
  line-height: normal;
  text-align: center;
  margin-top: 0.4rem;
}

.row-placeholder {
  width: 7rem; /* Same width as the button */
  height: 3.2rem; /* Same height as the button */
  background: transparent; /* Keeps it invisible */
  display: inline-block; /* Keeps it in layout */
}

.job-box__detail--btn-row .row-btn:hover {
  background: #0F54A2;
  color: #ffffff;
}
.job-box__detail--btn-row .row-btn + .row-btn {
  margin-left: 0.4rem;
}
.job-box__steps {
  display: flex;
  align-items: center;
  height: 8rem;
  flex-grow: 1;
  position: relative;
}
.job-box__steps .step-btn {
  height: 8rem;
  flex-grow: 1;
  flex-basis: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #CECECE;
  border: 0.1rem solid #000000;
  border-right: none;
  padding-left: 2.4rem;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #9C9C9C;
  line-height: normal;
  text-align: center;
  position: relative;
}
.job-box__steps .step-btn:nth-child(2) {
  border-radius: 0.4rem 0 0 0.4rem;
}
.job-box__steps .step-btn::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4rem 0 4rem 2rem;
  border-color: transparent transparent transparent #CECECE;
  transform: rotate(0deg);
  position: absolute;
  top: 0;
  right: -1.9rem;
  z-index: 15;
}
.job-box__steps .step-btn::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4rem 0 4rem 2rem;
  border-color: transparent transparent transparent #000000;
  transform: rotate(0deg);
  position: absolute;
  top: 0;
  right: -2rem;
  z-index: 10;
}
.job-box__steps .step-btn.done {
  background-color: #0F54A2;
  color: #ffffff;
}
.job-box__steps .step-btn.done::after {
  border-color: transparent transparent transparent #0F54A2;
}
.job-box__steps .step-btn.current {
  background-color: #1AA9DE;
  color: #ffffff;
}
.job-box__steps .step-btn.current::after {
  border-color: transparent transparent transparent #1AA9DE;
}
.job-box__steps .step-btn .step-info {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.job-box__steps .step-btn .step-info p {
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  color: #ffffff;
  line-height: 1.4;
  text-align: center;
}
.job-box__steps .step-btn .step-info .action-btn {
  background-color: #ffffff;
  width: 5.8rem;
  height: 2.1rem;
  border-radius: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #0F54A2;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
.job-box__steps .step-btn .step-info .action-btn:hover {
  color: #ffffff;
  background-color: #0F54A2;
}
.job-box__steps .step-percent {
  background-color: #ffffff;
  width: 5.5rem;
  height: 5.5rem;
  border: 0.1rem solid #000000;
  border-radius: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -2.8rem;
  z-index: 20;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: #0F54A2;
  line-height: 1;
  text-align: center;
}
.job-box + .job-box {
  margin-top: 2rem;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-basis-10 {
  flex-basis: 1rem;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.h-100 {
  height: 10rem;
}

.searchWrapper {
  border: 0.1rem solid #CACACA !important;
  border-radius: 0.4rem !important;
  min-height: 4rem !important;
  padding: 5px !important;
  background-color: #ffffff;
}
.searchWrapper .chip {
  background: #0F54A2 !important;
  font-size: 13px;
  line-height: 19px;
  margin-top: 0.3rem !important;
  margin-bottom: 0.3rem !important;
  margin-right: 0.4rem;
}

.multiSelectContainer input {
  margin-top: 0.6rem;
}
.multiSelectContainer ul {
  background-color: #ffffff;
  height: 15rem;
  border: 0.1rem solid #CACACA;
  border-radius: 0.4rem;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  overflow: "auto";
}
.multiSelectContainer ul li {
  padding: 0 1rem !important;
}
.multiSelectContainer ul li:hover {
  background: none;
}

.optionContainer .highlightOption {
  background: none;
  color: #fff;
}

.alert-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.alert-cont__icon {
  height: 3.6rem;
  margin-bottom: 2rem;
}
.alert-cont__title {
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #0F54A2;
  line-height: normal;
  text-align: center;
  border-bottom: 0.1rem solid #0F54A2;
  margin-bottom: 0.8rem;
}
.alert-cont__text {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000;
  line-height: normal;
  text-align: center;
}
.alert-cont__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.8rem;
}
.alert-cont__footer .solid-btn {
  background-color: #0F54A2;
  border: none;
  width: 12.4rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
}
.alert-cont__footer .solid-btn:hover {
  background-color: #0b3c73;
}
.alert-cont__footer .solid-btn + .solid-btn {
  margin-left: 0.6rem;
}
.alert-cont__footer .solid-btn + .outline-btn {
  margin-left: 0.6rem;
}
.alert-cont__footer .solid-btn.danger {
  background-color: #A20F0F;
}
.alert-cont__footer .solid-btn.danger:hover {
  background-color: #730b0b;
}
.alert-cont__footer .outline-btn {
  background-color: #ffffff;
  border: 0.1rem solid #0F54A2;
  width: 12.4rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0F54A2;
  cursor: pointer;
  transition: 0.5s;
}
.alert-cont__footer .outline-btn:hover {
  color: #ffffff;
  background-color: #0F54A2;
}

.mt-0 {
  margin-top: 0 !important;
}

.w-100p {
  width: 100%;
}

.w-150 {
  width: 15rem !important;
}

.font-size-18 {
  font-size: 1.8rem !important;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.position-relative {
  position: relative;
}

.ml-8 {
  margin-left: 0.8rem;
}

.grid-form-dark {
  background: #27303D;
  padding: 1.6rem 3.2rem;
  border-radius: 1rem;
}
.grid-form-dark .form-control__label {
  color: #ffffff;
}

.number-input {
  display: flex;
  background-color: #ffffff;
  border: 0.1rem solid #CACACA;
  border-radius: 0.4rem;
  height: 4rem;
  overflow: hidden;
}
.number-input__btn {
  width: 2rem;
  height: 3.8rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: #000000;
}
.number-input__btn:hover {
  background-color: #CACACA;
}
.number-input__input {
  width: 100%;
  height: 3.8rem;
  background-color: #ffffff;
  border: none;
  border-right: 0.1rem solid #CACACA;
  border-left: 0.1rem solid #CACACA;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  flex-grow: 1;
}

.filter-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filter-row__btn {
  background-color: #ffffff;
  height: 3.2rem;
  border-radius: 3.2rem;
  border: 0.1rem solid #0F54A2;
  padding: 0 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: #0F54A2;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  transition: 0.5s;
}
.filter-row__btn input {
  display: none;
}
.filter-row__btn:has(:checked) {
  color: #ffffff;
  background-color: #0F54A2;
}

.filter-dropdown {
  position: absolute;
  top: calc(100% + 0.2rem);
  right: 0;
  z-index: 100;
  min-width: 11rem;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  background-color: #ffffff;
  border: 0.1rem solid #F8F8F8;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
}
.filter-dropdown__btn {
  background: none;
  border: none;
  width: 100%;
  height: 2.4rem;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.5s;
  white-space: nowrap;
}
.filter-dropdown__btn:hover {
  color: #0F54A2;
}

.sign-canvas {
  background-color: #ffffff;
  border: 0.1rem solid #000000;
  border-radius: 0.4rem;
}

.top-page-header {
  background-color: #ffffff;
  min-height: 5.4rem;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  padding-right: 1.8rem;
  padding-left: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-page-header__title {
  font-family: "Inter", sans-serif;
  font-size: 3.2rem;
  font-weight: 600;
  color: #0E1827;
  text-align: left;
}
.top-page-header__user {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}
.top-page-header__user--pic {
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 3.4rem;
  object-fit: cover;
  margin-left: 0.5rem;
}
.top-page-header__user--text {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: normal;
  color: #27303D;
  text-align: right;
}
.top-page-header__icon-btn {
  width: 2.4rem;
  height: 2.4rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.top-page-header__icon-btn img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.main-content {
  background-color: #EAECED;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-area {
  flex-grow: 1;
  padding: 0 3.2rem 3.2rem;
  display: flex;
  flex-direction: column;
}
.content-area__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 0;
}
.content-area__header--btn {
  background: #0F54A2;
  width: 23.5rem;
  height: 3.3rem;
  border-radius: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  color: #ffffff;
}
.content-area__header--btn:hover {
  background: #0b3c73;
}
.content-area__header--search {
  background-color: #ffffff;
  width: 20.4rem;
  height: 3.4rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.2rem #0F54A2;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.content-area__header--search .search-icon {
  width: 1.6rem;
  height: 1.6rem;
  object-fit: contain;
}
.content-area__header--search .search-input {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 0.7rem;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
}
.content-area__header--search .search-input::placeholder {
  color: #E0E0E0;
}
.content-area__header--pagination {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
}
.content-area__header--pagination .pagination-btn {
  background-color: #ffffff;
  border: none;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}
.content-area__header--pagination .pagination-btn.nav {
  font-size: 1.5rem;
  color: #0F54A2;
  line-height: 2;
}
.content-area__header--pagination .pagination-btn:hover {
  color: #ffffff;
  background-color: #0F54A2;
}
.content-area__header--pagination .pagination-btn.active {
  color: #ffffff;
  background-color: #0F54A2;
}
.content-area__header--pagination .pagination-btn + .pagination-btn {
  margin-left: 0.4rem;
}
.content-area__header--filter-bar {
  display: flex;
  margin-top: 1.2rem;
}
.content-area__header--filter-bar .filter-btn {
  flex-grow: 1;
  flex-basis: 1rem;
  background-color: #ffffff;
  height: 2.4rem;
  border-radius: 0.4rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: #1E1E1E;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
}
.content-area__header--filter-bar .filter-btn:hover {
  background-color: #0F54A2;
  color: #ffffff;
}
.content-area__header--filter-bar .filter-btn + .filter-btn {
  margin-left: 0.8rem;
}
.content-area__header--filter-bar .filter-btn .fa {
  margin-right: 1.2rem;
}
.content-area__header--filter-row {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.content-area__header--filter-row .filter-row-label {
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 0.8rem;
}
.content-area__header--filter-row .filter-row-btn {
  background-color: #ffffff;
  width: 11.8rem;
  height: 2.9rem;
  border-radius: 0.4rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: #1E1E1E;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
}
.content-area__header--filter-row .filter-row-btn:hover {
  background-color: #27303D;
  color: #ffffff;
}
.content-area__header--filter-row .filter-row-btn.active {
  background-color: #27303D;
  color: #ffffff;
  border: 0.2rem solid #ffffff;
}
.content-area__header--filter-row .filter-row-btn + .filter-row-btn {
  margin-left: 0.8rem;
}
.content-area.dashboard {
  height: 1rem;
  padding: 0 1.6rem 1.6rem;
}
.content-area.dashboard .content-area__header {
  padding: 3.2rem 1.6rem 0;
}
.content-area.dashboard .dashboard__charts {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: auto;
}
.content-area.dashboard .dashboard__charts__col-1 {
  width: 100%;
  padding: 1.6rem;
}
.content-area.dashboard .dashboard__charts__col-1by2 {
  width: 50%;
  padding: 1.6rem;
}
.content-area.dashboard .dashboard__charts__col-1by3 {
  width: 33.333333%;
  padding: 1.6rem;
}
.content-area.dashboard .dashboard__charts__col-2by3 {
  width: 66.666667%;
  padding: 1.6rem;
}
.content-area.dashboard .dashboard__charts .chart-box {
  background-color: #ffffff;
  min-height: 30rem;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}
.content-area.dashboard .dashboard__charts .chart-box__title {
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}
.content-area.dashboard .dashboard__charts.scroll-sm::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background: #EAECED;
}
.content-area.dashboard .form-control {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-area.dashboard .form-control + .form-control {
  margin-left: 1.6rem;
}
.content-area.dashboard .form-control__label {
  margin-right: 0.6rem;
  margin-bottom: 0;
}
.content-area.dashboard .form-control__input {
  height: 3rem;
}

.foreman-detail {
  display: flex;
  height: 100%;
}
.foreman-detail__pic {
  width: 26rem;
  position: relative;
  margin-right: 2rem;
}
.foreman-detail__pic .foreman-pic {
  position: absolute;
  width: 26rem;
  height: 100%;
  top: 0;
  z-index: 100;
  object-fit: cover;
}
.foreman-detail__pic .file-upload {
  position: absolute;
  bottom: 4.8rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 110;
  width: 16.6rem;
  height: 3.6rem;
  border-radius: 3.6rem;
  background-color: #0F54A2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
}
.foreman-detail__pic .file-upload:hover {
  background-color: #0b3c73;
}
.foreman-detail__pic .file-upload input {
  display: none;
}
.foreman-detail__content {
  flex-grow: 1;
}

.customer-complain {
  border-top: 0.1rem solid #9C9C9C;
  margin-top: 1.8rem;
  padding: 1.8rem 0;
}
.customer-complain__title {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 0.8rem;
  padding-left: 3.2rem;
}
.customer-complain__row {
  display: flex;
  align-items: center;
}
.customer-complain__row--number {
  background-color: #000000;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;
  flex-shrink: 0;
}
.customer-complain__row--checkbox {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1.4rem;
  flex-shrink: 0;
}
.customer-complain__row .form-control__input {
  flex-grow: 1;
}
.customer-complain__row--close-btn {
  background-color: #B23F3F;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: 2.5rem;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  flex-shrink: 0;
  margin-left: 1.4rem;
}
.customer-complain__row--close-btn:hover {
  background-color: #792b2b;
}
.customer-complain__row--close-btn .icon {
  width: 1rem;
}
.customer-complain__row--check-btn {
  background-color: #0F54A2;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: 2.5rem;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  flex-shrink: 0;
  margin-left: 1.4rem;
}
.customer-complain__row--check-btn:hover {
  background-color: #09305c;
}
.customer-complain__row--check-btn .icon {
  width: 1.4rem;
}
.customer-complain__row + .customer-complain__row {
  margin-top: 1rem;
}
.customer-complain__row .form-add-new-btn {
  width: 11rem;
  margin-left: 1.4rem;
}
.customer-complain.light-gray {
  background-color: #EFEFEF;
  padding: 1.6rem;
  border-radius: 1rem;
  max-height: 31rem;
  overflow: auto;
}
.customer-complain.light-gray.scroll-sm::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background: #EFEFEF;
}

.new-complain {
  border-top: 0.1rem solid #9C9C9C;
  margin-top: 1.8rem;
  padding: 1.8rem 0;
}
.new-complain__btn {
  width: 4.8rem;
  height: 4rem;
  border-radius: 0.4rem;
  background: none;
  border: none;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  margin-left: 1.2rem;
}
.new-complain__btn .microphone-icon {
  height: 1.8rem;
}
.new-complain__btn .photo-upload-icon {
  height: 1.9rem;
}
.status-badge {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: all 0.15s ease-in-out;
}

.status-badge.pending-reviewed {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.status-badge.cancelled {
  color: #856404;
  background-color: #ffc107;
  border-color: #ffc107;
}

.status-badge.rejected {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.status-badge.accepted {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.new-complain__btn1 {
  width: 12rem;
  height: 4rem;
  border-radius: 0.4rem;
  background: none;
  border: none;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  margin-left: 1.2rem;
}
.new-complain__btn1 .microphone-icon {
  height: 1.8rem;
  margin-left: 1rem;
}

.part-scope {
  background-color: #EFEFEF;
  padding: 1.6rem;
  border-radius: 1rem;
  max-height: 31rem;
  overflow: auto;
  margin: 1.8rem 0;
}
.part-scope__title {
  font-family: "Inter", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  text-align: center;
  margin-bottom: 1.6rem;
}
.part-scope.scroll-sm::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background: #EFEFEF;
}
.part-scope--row-col-label {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-align: center;
}
.part-scope--row-col-1 {
  width: 2.5rem;
  margin-right: 0.7rem;
  flex-shrink: 0;
}
.part-scope--row-col-2 {
  width: auto;
  margin-right: 1.4rem;
  flex-shrink: 0;
  flex-grow: 1;
}
.part-scope--row-col-3 {
  width: 8rem;
  margin-right: 0 !important;
  flex-shrink: 0;
  flex-grow: 0 !important;
  text-align: center;
}
.part-scope--row-col-text {
  width: 3rem;
  margin-right: 0;
  flex-shrink: 0;
  flex-grow: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.part-scope--row-col-4 {
  width: 11rem;
  margin-right: 1.4rem;
  flex-shrink: 0;
  flex-grow: 0 !important;
  text-align: center;
}
.part-scope--row-col-5 {
  width: 11rem;
  margin-right: 0 !important;
  flex-shrink: 0;
  flex-grow: 0 !important;
}
.part-scope--row-col-6 {
  width: 2.5rem;
  margin-left: 1.4rem;
  flex-shrink: 0;
}
.part-scope .status {
  height: 4rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
}
.part-scope .status.none {
  background-color: #D3D3D3;
  color: #9C9C9C;
}
.part-scope .status.in-stock {
  background-color: #63FF1A;
  color: #000000;
}
.part-scope .status.out-stock {
  background-color: #FF1A1A;
  color: #000000;
}

.waiting-for-parts {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  width: 28rem;
}
.waiting-for-parts__checkbox {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.4rem;
  flex-shrink: 0;
}
.waiting-for-parts__cont {
  background-color: #D3D3D3;
  height: 3.6rem;
  border-radius: 0.4rem;
  flex-grow: 1;
  padding: 0 1.4rem;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.waiting-for-parts__cont .green-text {
  color: #0DB309;
}
.waiting-for-parts__cont .red-text {
  color: #B23F3F;
}
.waiting-for-parts.disabled .waiting-for-parts__cont {
  color: #A7A7A7;
}

.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.coming-soon__title {
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.8rem;
}
.coming-soon__outline-btn {
  background-color: #ffffff;
  border: 0.1rem solid #0F54A2;
  width: 23.2rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0F54A2;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
}
.coming-soon__outline-btn:hover {
  color: #ffffff;
  background-color: #0F54A2;
}

.login-form {
  background-color: #ffffff;
  width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 7.2rem;
}
.login-form__logo {
  height: 11rem;
}
.login-form__title {
  font-family: "Inter", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  color: #2366B6;
  text-align: center;
  line-height: 1;
  margin-top: 1.8rem;
  margin-bottom: 4.6rem;
}
.login-form__input {
  background: #ffffff;
  width: 100%;
  height: 8.6rem;
  border-radius: 1rem;
  border: 0.1rem solid #0F54A2;
  padding: 0 2.4rem;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
}
.login-form__forgot-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #9C9C9C;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  align-self: flex-end;
  cursor: pointer;
  transition: 0.5s;
}
.login-form__forgot-btn:hover {
  color: #0F54A2;
}
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh; 
  width: 95%;
  position: fixed; 
  top: 5.4rem;
  left: 20rem;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000; 
}

.loading-screen img {
  width: 100px; 
  height: 100px; 
}
.loading-gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loading-gif-container img {
  width: 100px; 
  height: 100px;
}
.login-form__login-btn {
  background-color: #062242;
  border: none;
  width: 18.5rem;
  height: 6.8rem;
  border-radius: 6.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 5.2rem;
  text-decoration: none;
}
.login-form__login-btn:hover {
  background-color: #020a13;
}
.sigCanvas {
  border: 1px solid #000;
  background-color: white;
  width: 100%;
  height: 100%;
}
.login-bg {
  flex-grow: 1;
  background-image: url(./../../public/images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

