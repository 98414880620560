*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
  scroll-behavior: smooth;
  font-size: 62.5%;

  // @include respond(tab-land) {
  //   //font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
  //   font-size: 45%;
  // }

  // @include respond(tab-port) {
  //   //font-size: 50%; //1rem = 8px, 8/16 = 50%
  //   font-size: 37%;
  // }

  // @include respond(big-desktop) {
  //   font-size: 75%; //1rem = 12px, 12/16 = 75%
  // }

  // @include respond(phone) {
  //   font-size: 37.5%; //1rem = 6px, 6/16 = 37.5%
  // }

  // if the breakpoint is less than or equal to 360px
  @include media("<=phone") {
    font-size: 45%;
    overflow-x: hidden;
  }

  // if the breakpoint is greater than 360px and less than or eqaul to 425px
  @include media(">phone", "<=425px") {
    font-size: 45%;
    overflow-x: hidden;
  }

  // if the screen is going to be greater than 425px and less than or eqaul to 768px
  @include media(">425px", "<=tablet") {
    font-size: 45%;
    overflow-x: hidden;
  }

  // if the screen is going to be greater than 768px and less than 1366px
  @include media(">tablet", "<desktop") {
    font-size: 62.5%;
    overflow-x: hidden;
  }

  // if the screen is going to be greater than or equal to 1366px
  @include media(">=desktop") {
    font-size: 62.5%;
    overflow-x: hidden;
  }

  // if the screen is going to be greater than or equal to 1920px
  @include media(">=LGdesktop") {
    font-size: 65.5%;
    overflow-x: hidden;
  }

  // @include media(">=LGdesktop", "retina2x") {
  //   font-size: 62.5%;
  // }
}


body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
  overflow-x: hidden;
}

.main-wrapper {
  height: 100vh;
  display: flex;
}