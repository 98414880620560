@import "abstracts/functions";
@import "abstracts/breakpoints";
@import "abstracts/variables";

@import "base/base";

@import "components/components";

@import "layout/header";

.main-content {
    background-color: #EAECED;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.content-area {
    flex-grow: 1;
    padding: 0 3.2rem 3.2rem;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.4rem 0;

        &--btn {
            background: $color-primary;
            width: 23.5rem;
            height: 3.3rem;
            border-radius: 3.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            transition: .5s;
            font-family: "Inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: normal;
            color: #ffffff;

            &:hover {
                background: darken($color-primary, 10%);
            }
        }

        &--search {
            background-color: #ffffff;
            width: 20.4rem;
            height: 3.4rem;
            border-radius: .5rem;
            box-shadow: 0 0 .2rem #0F54A2;
            padding: 0 1rem;
            display: flex;
            align-items: center;

            .search-icon {
                width: 1.6rem;
                height: 1.6rem;
                object-fit: contain;
            }

            .search-input {
                background: none;
                border: none;
                outline: none;
                padding: 0;
                margin: 0 0 0 .7rem;
                width: 100%;
                font-family: "Inter", sans-serif;
                font-size: 1.4rem;
                font-weight: 400;
                text-align: left;

                &::placeholder {
                    color: #E0E0E0;
                }
            }
        }

        &--pagination {
            display: flex;
            align-items: center;
            margin-top: .8rem;

            .pagination-btn {
                background-color: #ffffff;
                border: none;
                width: 2.6rem;
                height: 2.6rem;
                border-radius: 2.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: .5s;
                font-family: "Inter", sans-serif;
                color: #000000;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1;
                text-align: center;

                &.nav {
                    font-size: 1.5rem;
                    color: $color-primary;
                    line-height: 2;
                }

                &:hover {
                    color: #ffffff;
                    background-color: $color-primary;
                }

                &.active {
                    color: #ffffff;
                    background-color: $color-primary;
                }

                &+.pagination-btn {
                    margin-left: .4rem;
                }
            }
        }

        &--filter-bar {
            display: flex;
            margin-top: 1.2rem;

            .filter-btn {
                flex-grow: 1;
                flex-basis: 1rem;
                background-color: $color-white;
                height: 2.4rem;
                border-radius: .4rem;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Inter", sans-serif;
                color: #1E1E1E;
                font-size: 1.2rem;
                font-weight: 500;
                cursor: pointer;
                transition: .5s;

                &:hover {
                    background-color: $color-primary;
                    color: $color-white;
                }

                &+.filter-btn {
                    margin-left: .8rem;
                }

                .fa {
                    margin-right: 1.2rem;
                }
            }
        }

        &--filter-row {
            display: flex;
            align-items: center;
            margin-top: 2rem;

            .filter-row-label {
                font-family: "Inter", sans-serif;
                color: $color-black;
                font-size: 1.2rem;
                font-weight: 500;
                margin-right: .8rem;
            }

            .filter-row-btn {
                background-color: $color-white;
                width: 11.8rem;
                height: 2.9rem;
                border-radius: .4rem;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Inter", sans-serif;
                color: #1E1E1E;
                font-size: 1.2rem;
                font-weight: 500;
                cursor: pointer;
                transition: .5s;

                &:hover {
                    background-color: #27303D;
                    color: $color-white;
                }

                &.active {
                    background-color: #27303D;
                    color: $color-white;
                    border: .2rem solid $color-white;
                }

                &+.filter-row-btn {
                    margin-left: .8rem;
                }
            }
        }
    }
}

.foreman-detail {
    display: flex;
    height: 100%;

    &__pic {
        width: 26rem;
        position: relative;
        margin-right: 2rem;

        .foreman-pic {
            position: absolute;
            width: 26rem;
            height: 100%;
            top: 0;
            z-index: 100;
            object-fit: cover;
        }

        .file-upload {
            position: absolute;
            bottom: 4.8rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 110;
            width: 16.6rem;
            height: 3.6rem;
            border-radius: 3.6rem;
            background-color: $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Inter", sans-serif;
            font-size: 1.4rem;
            font-weight: 700;
            color: $color-white;
            cursor: pointer;
            transition: .5s;

            &:hover {
                background-color: darken($color-primary, 10%);
            }

            input {
                display: none;
            }
        }
    }

    &__content {
        flex-grow: 1;
    }
}