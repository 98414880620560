.left-nav {
    background-color: $color-secondary;
    width: 18.5rem;
    overflow: auto;

    &__logo {
        height: 14.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2.4rem 2.4rem;
        border-bottom: .1rem solid #27303D;

        &--img {
            width: 12rem;
        }
    }

    &__nav {
        padding-left: .8rem;
        padding-right: 1.3rem;

        &--btn {
            width: 100%;
            height: 4.2rem;
            border-radius: .5rem;
            display: flex;
            align-items: center;
            background: none;
            border: none;
            text-decoration: none;
            cursor: pointer;
            padding: 0 .7rem;
            margin: .3rem 0;

            &.active {
                background: rgba(#D9D9D9, .3);
            }

            .nav-btn-text {
                font-family: "Inter", sans-serif;
                font-size: 1.2rem;
                font-weight: 600;
                color: $color-light-text;
                text-align: left;
                flex-grow: 1;
            }

            .nav-btn-icon {
                width: 2.9rem;
                margin-right: 1rem;
            }

            .arrow-icon {
                width: .7rem;
                transition: .5s;
                transform: rotate(0deg);
            }
        }

        &--sub-nav {
            width: 100%;

            .sub-nav-btn {
                width: 100%;
                height: 4.2rem;
                background: none;
                border: none;
                border-radius: .5rem;
                cursor: pointer;
                transition: .5s;
                display: flex;
                align-items: center;
                font-family: "Inter", sans-serif;
                font-size: 1.2rem;
                font-weight: 600;
                color: $color-light-text;
                text-align: left;
                text-decoration: none;
                padding-left: 4.7rem;

                &.active {
                    background: rgba(#D9D9D9, .3);
                }
            }
        }
    }
}

.content-box {
    background-color: #ffffff;
    flex-grow: 1;
    border-radius: 2rem;
    padding: 1.4rem;
    display: flex;
    height: 1rem;

    &.transparent {
        background: none;
        border-radius: 0;
        padding: 0;
    }
}

.data-table {
    flex-grow: 1;
    overflow: auto;
    padding: 0 1.2rem;

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            font-family: "Inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 600;
            color: $color-dark-text;

            th {
                background: #ffffff;
                height: 6rem;
                border-bottom: 0.2rem solid #D8DCE2;
                position: sticky;
                top: 0;
            }
        }

        tbody {
            font-family: "Inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: $color-dark-text;

            td {
                height: 4.6rem;
                border-bottom: .2rem solid #D8DCE2;
                text-align: center;
            }
        }

        .view-btn {
            background: #ffffff;
            border: none;
            height: 2.4rem;
            border-radius: 2.4rem;
            border: .1rem solid $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Inter", sans-serif;
            font-size: 1.2rem;
            font-weight: 500;
            color: #0F54A2;
            padding: 0 1.6rem;
            margin: auto;
            cursor: pointer;
            transition: .5s;

            &:hover {
                color: #ffffff;
                background-color: $color-primary;
            }
        }
    }
}

.data-table-title {
    font-family: "Inter", sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-black;
    line-height: normal;
    margin-bottom: 1rem;
}

.data-table-2 {
    flex-grow: 1;
    overflow: auto;

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            font-family: "Inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 600;
            color: $color-dark-text;

            th {
                background: #ffffff;
                height: 3.4rem;
                border-top: 0.1rem solid #CACACA;
                border-bottom: 0.1rem solid #CACACA;


                &:first-child {
                    border-left: 0.1rem solid #CACACA;
                }

                &:last-child {
                    border-right: 0.1rem solid #CACACA;
                }
            }
        }

        tbody {
            font-family: "Inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: $color-dark-text;

            td {
                height: 3.8rem;
                border-bottom: .2rem solid #D8DCE2;
                text-align: center;
            }
        }

        .view-btn {
            background: #ffffff;
            border: none;
            height: 2.4rem;
            border-radius: 2.4rem;
            border: .1rem solid $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Inter", sans-serif;
            font-size: 1.2rem;
            font-weight: 500;
            color: #0F54A2;
            padding: 0 1.6rem;
            margin: auto;
            cursor: pointer;
            transition: .5s;

            &:hover {
                color: #ffffff;
                background-color: $color-primary;
            }
        }
    }
}

.scroll-sm::-webkit-scrollbar {
    width: 0.8rem;
}

.scroll-sm::-webkit-scrollbar-track {
    border-radius: 0.8rem;
    background: #ffffff;

    &.scroll-bg-light {
        background: #eaeced;
    }
}

.scroll-sm::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    background: #B7BABF;
    border: none;
}

.scroll-sm::-webkit-scrollbar-thumb:hover {
    background: $color-primary;
}

.scroll-sm::-webkit-scrollbar-thumb:active {
    background: $color-primary;
}



.scroll-sm2::-webkit-scrollbar {
    width: 0.8rem;
}

.scroll-sm2::-webkit-scrollbar-track {
    border-radius: 0.8rem;
    background: #eaeced;
}

.scroll-sm2::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    background: #B7BABF;
    border: none;
}

.scroll-sm2::-webkit-scrollbar-thumb:hover {
    background: $color-primary;
}

.scroll-sm2::-webkit-scrollbar-thumb:active {
    background: $color-primary;
}

.modal-dark-bg {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.modal-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal {
    width: 104.4rem;
    height: 67.5rem;
    background: $color-white;
    z-index: 110;
    border-radius: 2rem;
    box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.25);
    padding: 4rem 4rem 2.4rem;
    display: flex;
    flex-direction: column;

    &__close-btn {
        background-color: #B23F3F;
        width: 2.5rem;
        height: 2.5rem;
        border: none;
        border-radius: 2.5rem;
        position: absolute;
        top: 5.4rem;
        right: 3.2rem;
        font-family: "Inter", sans-serif;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .5s;

        &:hover {
            background-color: darken(#B23F3F, 15%);
        }

        .icon {
            width: 1rem;
        }
    }

    &__title {
        font-family: "Inter", sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 4rem;
        color: $color-black;
        margin-bottom: 3.2rem;
        flex-shrink: 0;
    }

    &__content {
        flex-grow: 1;
        overflow: auto;
    }

    &__footer {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &.light-center {
        background: #EFEFEF;

        .modal__title {
            text-align: center;
        }

        .modal__footer {
            justify-content: center;
        }
    }
}

.grid-3-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2.8rem;
    row-gap: 2.2rem;

    &+.grid-3-form {
        margin-top: 2.8rem;
    }
}

.grid-2-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.8rem;
    row-gap: 2.2rem;

    &+.grid-2-form {
        margin-top: 2.8rem;
    }
}

.grid-1-form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2.8rem;
    row-gap: 2.2rem;

    &+.grid-1-form {
        margin-top: 2.8rem;
    }
}

.form-control {
    display: flex;
    flex-direction: column;

    &__label {
        font-family: "Inter", sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.2;
        color: $color-black;
        margin-bottom: .8rem;
    }

    &__input {
        width: 100%;
        height: 4rem;
        background-color: #ffffff;
        border: .1rem solid #CACACA;
        border-radius: .4rem;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        font-family: "Inter", sans-serif;
        color: #1E1E1E;
        font-size: 1.3rem;
        font-weight: 400;

        &::placeholder {
            color: #ABABAB;
            font-weight: 300;
        }
    }

    &__textarea {
        width: 100%;
        background-color: #ffffff;
        border: .1rem solid #CACACA;
        border-radius: .4rem;
        padding: 1.2rem;
        font-family: "Inter", sans-serif;
        color: #1E1E1E;
        font-size: 1.3rem;
        font-weight: 400;
        resize: none;

        &::placeholder {
            color: #ABABAB;
            font-weight: 300;
        }
    }
}

.form-add-new-btn {
    height: 4rem;
    background-color: $color-primary;
    border: none;
    border-radius: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-white;
    cursor: pointer;
    transition: .5s;

    &:hover {
        background-color: darken($color-primary, 10%);
    }

    &__icon {
        margin-right: 1.4rem;
    }
}

.mt-25 {
    margin-top: 2.5rem;
}

.mt-28 {
    margin-top: 2.8rem;
}

.form-action-btn {
    background-color: $color-primary;
    border: none;
    width: 18rem;
    height: 4rem;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-white;
    cursor: pointer;
    transition: .5s;

    &:hover {
        background-color: darken($color-primary, 10%);
    }
}

.score-row {
    display: flex;
    align-items: center;

    &__label {
        background-color: #CCD2E3;
        height: 3.2rem;
        border-radius: .4rem;
        flex-grow: 1;
        font-family: "Inter", sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
        color: $color-black;
        line-height: 1;
        display: flex;
        align-items: center;
        padding-left: .6rem;
    }

    &__input {
        width: 4.4rem;
        height: 3.2rem;
        border-radius: .4rem;
        background-color: #ffffff;
        border: .1rem solid #CACACA;
        margin-right: .4rem;
        margin-left: .6rem;
        font-family: "Inter", sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        color: $color-black;
        padding: 0 .8rem;
    }

    &__text {
        font-family: "Inter", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        color: $color-black;
    }

    &+.score-row {
        margin-top: 1.6rem;
    }
}

.job-box {
    background-color: #ffffff;
    width: 100%;
    height: 12.2rem;
    border-radius: .7rem;
    box-shadow: 0 0 .4rem rgba(0, 0, 0, 0.25);
    padding-right: 4rem;
    padding-left: 2.4rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &__detail {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-right: 3.4rem;

        &--title {
            font-family: "Inter", sans-serif;
            font-size: 1.8rem;
            font-weight: 700;
            color: $color-black;
            line-height: normal;
        }

        &--text {
            font-family: "Inter", sans-serif;
            font-size: 1rem;
            font-weight: 500;
            color: #898989;
            line-height: 1.3rem;
        }

        &--btn-row {
            display: flex;

            .row-btn {
                width: 7rem;
                height: 3.2rem;
                border-radius: 1rem;
                border: .1rem solid $color-primary;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $color-white;
                cursor: pointer;
                transition: .5s;
                font-family: "Inter", sans-serif;
                font-size: 1rem;
                font-weight: 500;
                color: $color-primary;
                line-height: normal;
                text-align: center;
                margin-top: .4rem;

                &:hover {
                    background: $color-primary;
                    color: $color-white;
                }

                &+.row-btn {
                    margin-left: .4rem;
                }
            }
        }
    }

    &__steps {
        display: flex;
        align-items: center;
        height: 8rem;
        flex-grow: 1;
        position: relative;

        .step-btn {
            height: 8rem;
            flex-grow: 1;
            flex-basis: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #CECECE;
            border: .1rem solid $color-black;
            border-right: none;
            padding-left: 2.4rem;
            font-family: "Inter", sans-serif;
            font-size: 1rem;
            font-weight: 500;
            color: #9C9C9C;
            line-height: normal;
            text-align: center;
            position: relative;

            &:nth-child(2) {
                border-radius: .4rem 0 0 .4rem;
            }

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 3.9rem 0 3.9rem 1.9rem;
                border-color: transparent transparent transparent #CECECE;
                transform: rotate(0deg);
                position: absolute;
                top: .1rem;
                right: -1.9rem;
                z-index: 15;
            }

            &::before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4rem 0 4rem 2rem;
                border-color: transparent transparent transparent #000000;
                transform: rotate(0deg);
                position: absolute;
                top: 0;
                right: -2rem;
                z-index: 10;
            }

            &.done {
                background-color: $color-primary;
                color: $color-white;

                &::after {
                    border-color: transparent transparent transparent $color-primary;
                }
            }

            &.current {
                background-color: #1AA9DE;
                color: $color-white;

                &::after {
                    border-color: transparent transparent transparent #1AA9DE;
                }
            }

            .step-info {
                margin-top: .8rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    font-family: "Inter", sans-serif;
                    font-size: .9rem;
                    font-weight: 300;
                    color: #ffffff;
                    line-height: 1.4;
                    text-align: center;
                }

                .action-btn {
                    background-color: $color-white;
                    width: 5.8rem;
                    height: 2.1rem;
                    border-radius: 2.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    font-family: "Inter", sans-serif;
                    font-size: .9rem;
                    font-weight: 600;
                    color: #0F54A2;
                    line-height: 1;
                    text-align: center;
                    cursor: pointer;
                    transition: .5s;

                    &:hover {
                        color: #ffffff;
                        background-color: #0F54A2;
                    }
                }
            }
        }

        .step-percent {
            background-color: $color-white;
            width: 5.5rem;
            height: 5.5rem;
            border: .1rem solid $color-black;
            border-radius: 5.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: -2.8rem;
            z-index: 20;
            font-family: "Inter", sans-serif;
            font-size: 1.4rem;
            font-weight: 600;
            color: #0F54A2;
            line-height: 1;
            text-align: center;
        }
    }

    &+.job-box {
        margin-top: 2rem;
    }
}

.flex-column {
    flex-direction: column;
}

.overflow-auto {
    overflow: auto;
}

.searchWrapper {
    border: .1rem solid #CACACA !important;
    border-radius: .4rem !important;
    min-height: 4rem !important;
    padding: 5px !important;
    background-color: $color-white;

    .chip {
        background: $color-primary !important;
        font-size: 13px;
        line-height: 19px;
        margin-top: .3rem !important;
        margin-bottom: .3rem !important;
        margin-right: .4rem;
    }
}

.multiSelectContainer {
    input {
        margin-top: .6rem;
    }

    ul {
        background-color: $color-white;
        height: 15rem;
        border: .1rem solid #CACACA;
        border-radius: .4rem;
        box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.25);
        overflow: 'auto';

        li {
            padding: 0 1rem !important;

            &:hover {
                background: none;
            }
        }
    }
}

.optionContainer {
    .highlightOption {
        background: none;
        color: #fff;
    }
}