.top-page-header {
    background-color: #ffffff;
    min-height: 5.4rem;
    box-shadow: 0 .4rem .4rem rgba(#000000, .25);
    padding-right: 1.8rem;
    padding-left: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
        font-family: "Inter", sans-serif;
        font-size: 3.2rem;
        font-weight: 600;
        color: #0E1827;
        text-align: left;
    }

    &__user {
        display: flex;
        align-items: center;
        margin-left: 2rem;

        &--pic {
            width: 3.4rem;
            height: 3.4rem;
            border-radius: 3.4rem;
            object-fit: cover;
            margin-left: .5rem;
        }

        &--text {
            font-family: "Inter", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: normal;
            color: #27303D;
            text-align: right;
        }
    }

    &__icon-btn {
        width: 2.4rem;
        height: 2.4rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;

        img {
            width: 2.4rem;
            height: 2.4rem;
            object-fit: contain;
        }
    }
}